import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
  CardActionArea,
  CardActions,
  Container,
  Box,
} from "@material-ui/core";
import { Fragment, React } from "react";
import { FadeInSection } from "./Helpers/FadeinSection";
import USAMap from "react-usa-map";
import states from "../Img/current_states.png";

const useStyles = makeStyles({
  media: {
    height: 0,
    paddingTop: "20%", // 16:9
  },
});

const WritingStates = () => {
  const classes = useStyles();
  const width = useMediaQuery("(min-width:645px)");
  const mapHandler = (event) => {
    alert(event.target.dataset.name);
  };
  const statesCustomConfig = () => {
    return {
      TX: {
        fill: "green",
        clickHandler: (event) => alert(event.target.dataset.name),
      },
      CA: {
        fill: "green",
      },
      WA: {
        fill: "Orange",
      },
      IL: {
        fill: "green",
      },
      FL: {
        fill: "green",
      },
      OR: {
        fill: "Orange",
      },
      NV: {
        fill: "Orange",
      },
      AZ: {
        fill: "Orange",
      },
      NJ: {
        fill: "green",
      },
    };
  };
  return (
    <Fragment>
      <section id="writingstates">
        <h2>Writing States of Domicile</h2>
        <FadeInSection>
          <Grid container>
            <Grid item xs="12" lg="12">
              <USAMap customize={statesCustomConfig()} onClick={mapHandler} />
            </Grid>
            <Grid item xs="6"></Grid>
            <Grid item xs="6">
              <Container>
                <Box
                  component="img"
                  sx={{
                    height: 65,
                    margin: 0.5,
                  }}
                  alt="Your logo"
                  src={states}
                />
              </Container>
            </Grid>
          </Grid>
        </FadeInSection>
      </section>
      <Grid item sm={12} style={{ padding: "20px" }}>
        <Typography variant="body2" >
        © 2024 Trailblazers Risk Retention Group, Inc. - All Rights Reserved.
        </Typography>
      </Grid>
    </Fragment>
  );
};

export default WritingStates;
