import { Grid, makeStyles, useMediaQuery } from "@material-ui/core";
import { Fragment, React, useLayoutEffect, useState } from "react";
import background from "../Img/home.jpg"
import logo from "../Img/companyLogo.png"



const useStyles = makeStyles((theme) => ({
    bannerStyle: {
        backgroundImage: `url(${background})`,
        backgroundColor: 'white',
        backgroundSize: 'cover',

    }
}));
const NavBar = () => {
    const classes = useStyles();
    const width = useMediaQuery('(min-width:770px)');

    return (
        <Fragment>
            <header id="home">
                <nav id="nav-wrap">
                    <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
                    <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

                    <ul id="nav" className="nav">
                        <li className="current"><a className="smoothscroll" href="#home">HOME</a></li>
                        <li><a className="smoothscroll" href="#about">ABOUT US </a></li>
                        <li><a className="smoothscroll" href="#claims">CLAIMS</a></li>
                        <li><a className="smoothscroll" href="#findagent">FIND AN AGENT</a></li>
                        <li><a className="smoothscroll" href="#contact">CONTACT US</a></li>
                        <li><a className="smoothscroll" href="#officeofcompliance">OFFICE OF COMPLIANCE</a></li>
                        <li><a className="smoothscroll" href="#preferedvendors">VENDORS</a></li>
                        {/* <li><a className="smoothscroll" href="#executiveteam">TEAM</a></li> */}
                        <li><a className="smoothscroll" href="#writingstates">WRITING STATES</a></li>
                    </ul>
                </nav>

                {
                    !width ?
                        <Grid container style={{ float: 'left' }}>

                            <Grid item xs={12} lg={6} md={6} >
                                <div className="banner-text">
                                    <img src={logo} width="40%"></img>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6} md={6} style={{ padding: '12% 50px', backgroundColor: 'rgb(0 0 0 / 50%)', color: 'white', textAlign: 'left', lineHeight: '20px' }}>
                                <p>
                                    In 2021 TRAILBLAZERS INSURANCE COMPANY, INC. RRG (Trailblazers) was formed by a group of experienced commercial insurance individuals committed to help you get where you are going with a minimum of trouble and expense and a maximum level of comfort in the knowledge that your on-going business risks have been properly transferred and managed.
                                </p>
                                <p>
                                    At Trailblazers we offer our insurance products with the value added of exceptional customer service through our General Agents and Retail Broker network throughout the United States. Trailblazers offers competitive rates, prompt and courteous service, effective loss control and risk management programs and most importantly stability of coverage.
                                </p>
                                <p>
                                    TRAILBLAZERS INSURANCE COMPANY, INC. RRG was formed under the Liability Risk Retention Act (LRRA) which is a federal law passed by Congress in 1986 to help U.S. businesses, professionals and municipalities obtain liability insurance which had become either unaffordable or unavailable due to the liability crisis in the United States. In passing the Liability Risk Retention Act, Congress provided insurance buyers with a marketplace solution to the liability crisis, enabling them to have a greater control of their liability insurance programs.
                                </p>

                            </Grid>
                        </Grid>
                        :

                        <Grid container style={{ float: 'left', height: '100%' }}>
                            <Grid item xs={12} sm={6} lg={6} md={6} style={{ padding: '12% 50px', backgroundColor: 'rgb(0 0 0 / 50%)', color: 'white', textAlign: 'left', lineHeight: '20px' }}>
                                <p>
                                    In 2021 TRAILBLAZERS INSURANCE COMPANY, INC. RRG (Trailblazers) was formed by a group of experienced commercial insurance individuals committed to help you get where you are going with a minimum of trouble and expense and a maximum level of comfort in the knowledge that your on-going business risks have been properly transferred and managed.
                                </p>
                                <p>
                                    At Trailblazers we offer our insurance products with the value added of exceptional customer service through our General Agents and Retail Broker network throughout the United States. Trailblazers offers competitive rates, prompt and courteous service, effective loss control and risk management programs and most importantly stability of coverage.
                                </p>
                                <p>
                                    TRAILBLAZERS INSURANCE COMPANY, INC. RRG was formed under the Liability Risk Retention Act (LRRA) which is a federal law passed by Congress in 1986 to help U.S. businesses, professionals and municipalities obtain liability insurance which had become either unaffordable or unavailable due to the liability crisis in the United States. In passing the Liability Risk Retention Act, Congress provided insurance buyers with a marketplace solution to the liability crisis, enabling them to have a greater control of their liability insurance programs.
                                </p>

                            </Grid>
                            <Grid item xs={12} sm={6} lg={6} md={6}>
                                <div className="banner-text" style={{ marginTop: '80px', marginLeft: '30px' }}>
                                    <img src={logo} width="40%"></img>
                                </div>
                            </Grid>
                        </Grid>
                }


                <p className="scrolldown">
                    <a className="smoothscroll" href="#coverages"><i className="icon-down-circle"></i></a>
                </p>

            </header>
        </Fragment>
    );
}

export default NavBar;