import { Card, CardContent, Grid, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import { Fragment, React, useEffect, useRef, useState } from "react";
//import background from "../Img/road.jpg"
import { FadeInSection } from "./Helpers/FadeinSection";


const useStyles = makeStyles({
    root: {
        minWidth: 275,
        color: '#838C95',
        padding: 10,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
        fontSize: 15
    },
    coveragesBackground: {
        //backgroundImage: `url(${background}) !important`,
        backgroundSize: '50% 100%',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    coveragesBackgroundMobil: {
        //backgroundImage: `url(${background}) !important`,
        backgroundSize: '100% 100%',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',

    }
});

const Coverages = () => {
    const classes = useStyles();
    const width = useMediaQuery('(min-width:800px)');
    return (
        <Fragment>
            <section id="coverages" className={width ? classes.coveragesBackground : classes.coveragesBackgroundMobil}>

                <Grid container>
                    <Grid item lg={6} sm={6} xs={12}>
                        <h2 style={{ color: '#838C95' }}>Coverages</h2>
                    </Grid>
                    <Grid item lg={6} xs={6}>

                    </Grid>
                </Grid>
                <FadeInSection>
                    <Grid container >
                        <Grid item xs="12" lg="3" style={{ padding: 20 }}>
                            <Card className={classes.root}>
                                <CardContent>
                                    <Typography component="p">
                                        <b>Auto liability:</b>
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary">
                                        <div>
                                            which protects you and your company by paying for bodily injury or property damages you may become legally liable for as a result of a covered automobile accident
                                    </div>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs="12" lg="3" style={{ padding: 20 }}>
                            <Card className={classes.root}>
                                <CardContent>
                                    <Typography component="p">
                                        <b>Medical payments:</b>
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary">
                                        <div>
                                            which pays medical expenses, up to your coverage limit, for you, your employees and your passengers arising from motor vehicle accidents – regardless of who is at fault
                                    </div>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </FadeInSection>
                <FadeInSection>
                    <Grid container >
                        <Grid item xs="12" lg="3" style={{ padding: 20 }}>
                            <Card className={classes.root}>
                                <CardContent>
                                    <Typography component="p">
                                        <b>Collision coverage:</b>
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary">
                                        <div>
                                            which pays for damage to your vehicle from another vehicle whether you hit another vehicle or object, another vehicle hits you, or your vehicle rolls over – regardless of who is at fault (subject to deductible)
                                    </div>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs="12" lg="3" style={{ padding: 20 }}>
                            <Card className={classes.root}>
                                <CardContent>
                                    <Typography component="p">
                                        <b>Uninsured and underinsured motorist coverage:</b>
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary">
                                        <div>
                                            which pays for medical expenses, loss of income and other damages owed to you, your employees (expenses that are not covered by workers’ compensation) or your passengers when an accident is caused by an uninsured or underinsured motorist
                                    </div>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </FadeInSection>
                <FadeInSection>
                    <Grid container>
                        <Grid item xs="12" lg="3" style={{ padding: 20 }}>
                            <Card className={classes.root}>
                                <CardContent>
                                    <Typography component="p">
                                        <b>Comprehensive coverage:</b>
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary">
                                        <div>
                                            which can help pay for damage to your vehicle from something other than another vehicle like vandalism, theft, weather events and accidents involving animals (subject to deductible)
                                    </div>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs="12" lg="3" style={{ padding: 20 }}>
                            <Card className={classes.root}>
                                <CardContent>
                                    <Typography component="p">
                                        <b>Comprehensive coverage:</b>
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary">
                                        <div>
                                            which can help pay for damage to your vehicle from something other than another vehicle like vandalism, theft, weather events and accidents involving animals (subject to deductible)
                                    </div>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </FadeInSection>
            </section >
        </Fragment >
    );
}

export default Coverages;