import { Fragment, React } from "react";
import NavBar from '../NavBar';
import Coverages from '../Coverages';
import { Parallax } from 'react-parallax';
import background from "../../Img/home.jpg";
import Claims from '../Claims';
import FindAgent from '../FindAgent';
import Contact from '../Contact';
import OfficeOfCompliance from '../OfficeOfCompliance';
import PreferedVendors from '../PreferedVendors';
import Team from '../Team';
import WritingStates from '../WritingStates';
import About from "../About";
const Main = () => {
    return (
        <Fragment>
            <Parallax bgImage={background} strength={200} bgImageStyle={{ width: "100%", objectFit: 'cover' }}>
                <NavBar />
            </Parallax>
            <About />
            <Claims />
            <FindAgent />
            <Contact />
            <OfficeOfCompliance />
            <PreferedVendors />
            {/* <Team /> */}
            <WritingStates />
        </Fragment>
    )
}
export default Main;