import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Fragment, React } from "react";
import { FadeInSection } from "./Helpers/FadeinSection";
import contact from "../Img/contact.jpg";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    color: "#838C95",
    padding: 10,
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  pos: {
    marginBottom: 12,
    fontSize: 15,
  },
  // contactBackground: {
  //     backgroundImage: `url(${background}) !important`,
  //     backgroundSize: '50% 100%',
  //     backgroundColor: 'white',
  //     backgroundRepeat: 'no-repeat',
  //     backgroundPosition: 'right',
  // },
  // contactBackgroundMobil: {
  //     backgroundImage: `url(${background}) !important`,
  //     backgroundSize: '100% 100%',
  //     backgroundColor: 'white',
  //     backgroundRepeat: 'no-repeat',
  //     backgroundPosition: 'right'
  // }
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
});

const Contact = () => {
  const classes = useStyles();
  const width = useMediaQuery("(min-width:645px)");
  return (
    <Fragment>
      <section id="contact">
        <h2>Contact</h2>
        <Grid container direction="row">
          <Grid item xs="12" lg="3">
            <Grid
              direction="row"
              container
              justify="center"
              style={{
                // backgroundColor: "#f1f1f169",
                paddingTop: "50px",
              }}
            >
              {/* <Grid item xs={2} style={{ marginBottom: "10px" }}></Grid> */}
              <Grid item xs={6}>
                Your Name
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  fullWidth
                  style={{
                    marginBottom: "10px",
                    font: "",
                    backgroundColor: "white",
                  }}
                />
              </Grid>
              {/* <Grid item xs={2}></Grid>
              <Grid item xs={2}></Grid> */}
              <Grid item xs={6}>
                Telephone
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "10px", backgroundColor: "white" }}
                />
              </Grid>
              {/* <Grid item xs={2}></Grid>
              <Grid item xs={2}></Grid> */}
              <Grid item xs={6}>
                E-mail
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  size="small"
                  fullWidth
                  variant="outlined"
                  style={{ marginBottom: "10px", backgroundColor: "white" }}
                />
              </Grid>
              <Grid item xs={6}>
                Message
              </Grid>
              <Grid item xs={6}>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  style={{ backgroundColor: "white" }}
                />
              </Grid>
              <Grid item xs={12} style={{ padding: "40px" }}>
                <Button variant="contained" color="primary">
                  Reset
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: "15px" }}
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs="12"
            lg="6"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Card style={{ width: "80%" }}>
              <CardMedia className={classes.media} image={contact} />
            </Card>
          </Grid>
          <Grid
            item
            xs="12"
            lg="3"
            style={{
              paddingRight: "70px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <p>
              <b>Direct contact </b>
            </p> */}
            <FadeInSection>
              <Typography
                variant="h6"
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                Direct contact
              </Typography>
              <Typography variant="body1" gutterBottom>
                Broker Appointments
                <a href="mailto:bap@gsiay.com"> bap@gsiay.com </a>
                <a href="tel:(706) 395-3800"><b><font size= "2">(706) 395-3800</font></b></a>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Loss Runs
                <a href="mailto:lossrunrequest@gsiay.com"> lossrunrequest@gsiay.com </a>
                <a href="tel:(706) 395-4200"><b><font size= "2">(706) 395-4200</font></b></a>
              </Typography>
            </FadeInSection>
          </Grid>
        </Grid>
        <Grid item sm={12} style={{ paddingTop: "20px" }}>
          <Typography variant="body1" gutterBottom>
            Trailblazers Risk Retention Group, Inc.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Strategic Risk Solutions; 522 Brandies Circle, Suite B4,
            Murfreesboro, TN 37128
          </Typography>
          <Typography variant="body2" gutterBottom>
            Susan Schoenberger #: (904) 701-0370
          </Typography>
        </Grid>
      </section>
    </Fragment>
  );
};

export default Contact;
