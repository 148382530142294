import { Button, Card, CardContent, CardMedia, Grid, makeStyles, TextField, Typography, useMediaQuery, CardActionArea, CardActions, Avatar } from "@material-ui/core";
import { Fragment, React } from "react";
import { FadeInSection } from "./Helpers/FadeinSection";
import ovitOntrak from "../Img/ovitOntrak.png";
import { useState } from "react";
import TeamCard from "./Helpers/TeamCard";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        margin: 20,
        borderBottomRightRadius: '10%'
    },
    root2: {
        margin: 20,
        boxShadow: '0px 4px 15px 5px #A8A8A8',

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
        fontSize: 15
    },
    // contactBackground: {
    //     backgroundImage: `url(${background}) !important`,
    //     backgroundSize: '50% 100%',
    //     backgroundColor: 'white',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundPosition: 'right',
    // },
    // contactBackgroundMobil: {
    //     backgroundImage: `url(${background}) !important`,
    //     backgroundSize: '100% 100%',
    //     backgroundColor: 'white',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundPosition: 'right'
    // }
    media: {
        height: 0,
        paddingTop: '80%', // 16:9
    },
    large: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        margin: '0 auto'
    },
}));

const Team = () => {
    const classes = useStyles();
    const width = useMediaQuery('(min-width:645px)');
    const [cardStyleHover, setCardStyleHover] = useState(classes.root);

    const setHoverStyle = (val) => {
        val ?
            setCardStyleHover(classes.root2)
            :
            setCardStyleHover(classes.root)
    }
    const showFlyer = () => {

    }
    return (
        <Fragment>
            <section id="executiveteam">
                <h2>Executive Team</h2>
                <FadeInSection>
                    <Grid container justify="center">
                        <Grid item xs="12" lg="3">
                            <TeamCard name="Anthony Stola" title="CEO" description="Chairman , Executive Officer" />
                        </Grid>
                        <Grid item xs="12" lg="3">
                            <TeamCard name="Susie Shoenberger" title="CFO" description="Chief Financial Officer" />
                        </Grid>
                        <Grid item xs="12" lg="3">
                            <TeamCard name="Neil Salters" description="Secretary and Executive Director Claims" />
                        </Grid>
                    </Grid>
                </FadeInSection>
            </section>
        </Fragment>
    );
}

export default Team;