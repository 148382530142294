import { Card, CardContent, CardMedia, Grid, makeStyles, Typography } from "@material-ui/core";
import { Fragment, React } from "react";
import { FadeInSection } from "./Helpers/FadeinSection";
import claims1 from "../Img/claims1.jpg";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        color: '#838C95',
        padding: 10,
        backgroundColor: 'transparent',
        boxShadow: 'none'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
        fontSize: 15
    },
    coveragesBackground: {
        // backgroundImage: `url(${background}) !important`,
        // backgroundSize: '50% 100%',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    coveragesBackgroundMobil: {
        // backgroundImage: `url(${background}) !important`,
        // backgroundSize: '100% 100%',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
});

const Claims = () => {
    const classes = useStyles();
    return (
        <Fragment>
            <section id="claims">
                <h2>Claims</h2>
                <Grid container direction="row-reverse">
                    <Grid item xs={12} md={6} lg={6} style={{ padding: 20 }}>
                        <Card>
                            <CardMedia className={classes.media} image={claims1} />
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6} md={6} style={{ padding: 20 }}>
                        <Card className={classes.root}>
                            <CardContent>
                                <Typography className={classes.pos} color="textSecondary">
                                    <FadeInSection>
                                        <p>
                                            The <b>TRAILBLAZERS INSURANCE COMPANY, INC. RRG</b> is committed to always provide our insured members with a professional and dedicated claims staff who will always be ready and able to help you in any way we can.
                                    </p>
                                        <p>
                                            Notify our Claims Dept. ASAP at <a href="tel:(949)359-5600"><b><font size= "4">(949) 359-5600</font></b></a> or email your claim information to <a href="mailto:claims@gsiay.com">claims@gsiay.com</a>
                                    </p>
                                        <br /><br /><br />
                                    </FadeInSection>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </section>
        </Fragment>
    );
}

export default Claims;