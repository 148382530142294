import { Card, CardContent, CardMedia, Grid, makeStyles, Typography } from "@material-ui/core";
import { Fragment, React } from "react";
import { FadeInSection } from "./Helpers/FadeinSection";
import about1 from "../Img/about1.jpg";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        color: '#838C95',
        padding: 10,
        backgroundColor: 'transparent',
        boxShadow: 'none'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
        fontSize: 15
    },
    coveragesBackground: {
        // backgroundImage: `url(${background}) !important`,
        // backgroundSize: '50% 100%',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    coveragesBackgroundMobil: {
        // backgroundImage: `url(${background}) !important`,
        // backgroundSize: '100% 100%',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
});

const About = () => {
    const classes = useStyles();

    return (
        <Fragment>
            <section id="about">
                <h2>About</h2>

                <Grid container direction="row-reverse">
                    <Grid item xs="12" lg="6" style={{ padding: 20 }}>

                        <Card className={classes.root}>
                            <CardContent>
                                <Typography className={classes.pos} color="textSecondary">
                                    <FadeInSection>
                                        <p>
                                            <b>TRAILBLAZERS INSURANCE COMPANY, INC. RRG</b> specializes in Commercial Auto Liability, with superior service and quick response to the needs of our insured members.
                                    </p>
                                    </FadeInSection>
                                    <FadeInSection>
                                        <p>
                                            We know and understand the unique needs of privately held commercial/trucking businesses.
                                            We recognize that prompt responses and truthful answers are what you are looking for, along with effective use of your insurance dollars. Our primary focus within the transportation sector is on motor carriers, freight brokers, forwarders and logistics providers, from single owner operators all the way up

                                    </p>
                                        <p>
                                            Trucks are all we write so you our insureds can benefit from our unmatched expertise and service.
                                    </p>
                                    </FadeInSection>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs="12" lg="6" style={{ padding: 20 }}>
                        <Card>
                            <CardMedia className={classes.media} image={about1} />
                        </Card>
                    </Grid>
                    <Grid item xs="12" lg="12" style={{ padding: 20, paddingTop: 0 }}>
                        <Card className={classes.root}>
                            <CardContent>
                                <Typography className={classes.pos} color="textSecondary">
                                    <FadeInSection>
                                        <p>
                                            <b>Our Mission</b>
                                        </p>
                                        <p>
                                            Our mission is to provide our agents , producers and insureds with the best possible quote on qualified accounts.

                                            We pledge to deliver superior service, and proudly maintain the highest level of ethical standards.
                                    </p>
                                    </FadeInSection>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>


            </section>
        </Fragment>
    );
}

export default About;