import { Card, CardContent, CardMedia, Grid, makeStyles, Typography } from "@material-ui/core";
import { Fragment, React } from "react";
import { FadeInSection } from "./Helpers/FadeinSection";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        color: '#838C95',
        padding: 10,
        backgroundColor: 'transparent',
        boxShadow: 'none'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
        fontSize: 15
    },
    coveragesBackground: {
        // backgroundImage: `url(${background}) !important`,
        // backgroundSize: '50% 100%',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    coveragesBackgroundMobil: {
        // backgroundImage: `url(${background}) !important`,
        // backgroundSize: '100% 100%',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
});

const OfficeOfCompliance = () => {
    const classes = useStyles();
    return (
        <Fragment>
            <section id="officeofcompliance">
                <h2>Office of Compliance</h2>
                <Grid container>
                    {/* <Grid item xs="12" lg="6" style={{ padding: 20 }}>
                        <Card>
                            <CardMedia className={classes.media} />
                        </Card>
                    </Grid> */}
                    <Grid item xs="12" lg="12" style={{ padding: 20 }}>
                        <Card className={classes.root}>
                            <CardContent>
                                <Typography className={classes.pos} color="textSecondary">
                                    <FadeInSection>
                                        <p>
                                            The Office of Compliance is committed to investigating reports and concerns regarding workplace safety or unfair dealings with our customers, suppliers, business partners as well as employees. For assistance, the Office of Compliance can be reached at:
                                    </p>
                                        <p>
                                            Compliance Hotline: Phone coming soon
                                        </p>
                                        <br />
                                        <br />
                                    </FadeInSection>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </section>
        </Fragment>
    );
}

export default OfficeOfCompliance;