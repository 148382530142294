import { Card, CardMedia, makeStyles } from "@material-ui/core";
import { Fragment, React } from "react";
import OVIT from "../Img/OVIT.png";


const useStyles = makeStyles({
    media: {
        height: 0,
        paddingTop: '100%', // 16:9
    },
});
const Ontrak = () => {
    const classes = useStyles();
    return (
        <Fragment>
            <img src={OVIT} width="50%" height="auto" />
        </Fragment>
    );
}

export default Ontrak;