import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Ontrak from './Components/Ontrak';
import Main from './Components/Index/Main';
function App() {
  return (
    <div className="App">

      <Router>
        <Switch>
          <Route exact path="/ontrak" component={Ontrak} />
          <Route path="/ontrak" component={Ontrak} />
          <Route path="/" component={Main} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
