import { Card, CardContent, makeStyles, Typography, Avatar } from "@material-ui/core";
import { Fragment, React } from "react";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        margin: 20,
        borderBottomRightRadius: '10%'
    },
    root2: {
        margin: 20,
        boxShadow: '0px 4px 15px 5px #A8A8A8',

    },
    large: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        margin: '0 auto'
    },
}));

const TeamCard = ({ name, title, description, image }) => {
    const classes = useStyles();
    const [cardStyleHover, setCardStyleHover] = useState(classes.root);

    const setHoverStyle = (val) => {
        val ?
            setCardStyleHover(classes.root2)
            :
            setCardStyleHover(classes.root)
    }
    return (
        <Fragment>
            <Card
                className={cardStyleHover}
                onMouseEnter={() => setHoverStyle(true)}
                onMouseLeave={() => setHoverStyle(false)}
            >
                <CardContent>
                    <Avatar alt={name} src={image} className={classes.large} />
                    <Typography gutterBottom variant="h5" component="h2">
                        {name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {title != "" ? title : null}
                        <br />
                        {description}
                    </Typography>
                </CardContent>
            </Card>
        </Fragment>
    );
}

export default TeamCard;