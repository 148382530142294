import { Fragment, React } from "react";

const FindAgent = () => {
    return (
        <Fragment>
            <section id="findagent">
                <div className="row">
                    <div className="twelve columns main-col">
                        <h2 >Find an agent</h2>
                        <br />
                        <br />
                        <br />
                        <h3>Under construction</h3>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                </div>

            </section>
        </Fragment>
    );
}

export default FindAgent;